import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Terms = ({ data }) => {
  const title = data.allContentfulLegalPages.nodes[0].title
  const content =
    data.allContentfulLegalPages.nodes[0].content.childMarkdownRemark.html

  return (
    <Layout>
      <SEO title={title} />
      <h1 className="mb-4">{title}</h1>
      <div className="mx-6 my-6 p-6 bg-white  rounded-lg shadow-light">
        <div
          className="px-6 text-black text-justify min-h-full legal"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulLegalPages(filter: { title: { eq: "Terms and Conditions" } }) {
      nodes {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default Terms
